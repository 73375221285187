import React from 'react'
import Terms from '../../components/Terms'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'

function donation() {
  return (
    <Layout border='2px solid #424242' lang='en-us'>
        <Seo title='Terms of use | Shukran' descriptionEn='Terms of use'  descriptionFr='Conditions d&apos;utilisation'/>
        <Terms fr={false}/>
    </Layout>
  )
}

export default donation